import React from "react"

import { footlink, footnote, /*footlink*/ } from "../shared/links"

import styles from "../../styles/main.module.css"

export default (props) =>  {
    let footnotes = [
        " These last ones do not really fit into my model of “idea generators,” but they opened up new ways of thinking for me, or helped question things I’d taken for granted.",
        "I don’t buy this as actually being how things are. I think most people do not necessarily think this way, but it is certainly pervasive, especially within the educated.",
        "By Dialectic, he means roughly logical argument. Dialectic is meant to act in opposition to (or cooperation with) Rhetoric. From Phaedrus’ point of view, good rhetoric has Quality, while good dialectic is True. Of course, there is also bad rhetoric—the type that uses underhanded tricks, but Phaedrus’ main distinction is between measurement in terms of Quality vs. in terms of Truth.",
        "Note, that The Church of Reason is not just another name for the universities. The Church of Reason is the modus operandi of the universities—it is their culture and their underlying maintenance of our rational mythos. The Church of Reason, in more trying times, could simply be a single person (i.e. it need not be institutionalized, though of course our society has institutionalized it, as it is our societal mythos). It’s not the concrete (i.e. the physical buildings, or the organizational structure), but instead the value system and set of methods used by the universities.",
        "Unfortunately, to reinvent a mythos you must first leave your current mythos. That is akin to insanity (which is why Phaedrus had a breakdown at the point when he’d finally escaped). I actually suspect that this process is an analogue to Carl Jung’s experiences while writing The Red Book.",
        "In ancient Greece Aretê refers to “excellence.” It is excellence in the sense of living to your full potential—of being effective in fulfilling your purpose. It’s a sort of all-encompassing concept describing the way one ought to live. It’s sometimes called “virtue,” but the moral facets of the term are not primary in importance to its meaning. Effectiveness is more important to Aretê than righteousness.",
        "It’s not exactly lack of passion, nor is it laziness. It’s the feeling of demotivation. It’s that the writers/workers simply do not want to work. They find no meaning whatsoever in their endeavor, and do the minimum necessary to yield the results they need. I will call this feeling the “lack” henceforth.",
        "He ties this in to the lack of authenticity in our society as well as loneliness.",
        "He also is also enraged by Plato’s Socratic dialogues. I thought Socrates was actually trying to highlight how dialectic could not be the source of truth, since if it were then dialectic would destroy everything we knew to be true. In that sense, I thought Socrates was on the side of Quality, and that he was using dialectic to kill itself. Evidently, most interpretations of Socrates are the exact opposite: that he is pro-dialectic. My reason for believing what I did was two-fold: firstly, I read Zen work first, and the dialogues later, so I came in with some preconceptions quite different from my educated friends; secondly, Xenophon described Socrates as a sort of stoic character, and given both Socrates’ time in the Peloponnesian war and his willingness to give himself once accused, I could not conceive of him believing in something so moronic (in my view) as dialectic being necessarily the ultimate truth, when he had experienced reality so directly. As NNT would say “suckers try to win arguments, nonsuckers try to win.” I didn’t think a (supposedly, virtuous) experienced elder, such as Socrates, could be unironically spending the end of life by trying to win arguments.",
        "Socrates’ opponents would fail to logically describe Quality, and then be made fun of. Consider Gorgias’ description of rhetoric as studying what is “the greatest… and the best” to which Socrates called him out for being “vague.” Of course he’s vague, he’s trying to describe Quality! You can’t describe Quality, so evidently Socrates will win the argument and humiliate Gorgias, but in the process he’s putting dialectic (i.e. Truth) over Quality. The whole methodology is busted and wrong—not Gorgias’ conception of rhetoric. It’s sad to see Quality destroyed so cruelly. In effect, Socrates uses dialectic to form word traps. He is using an underhanded trick. He is eschewing Quality! (Or so I interpret, assuming he is unironic).",
        "Robert explores the case of “mu” questions—questions that cannot be answered with “yes” or “no” because their context/framing is insufficient. He also explores some limitations of science, bringing up the observation that the more we know, the more hypotheses there are. These hypotheses grow so fast in number that they outrun knowledge itself. As knowledge progresses, we seem never to narrow down on Truth. Science cannot ultimately succeed (in this sense).",
        "More or less, you need to overturn the mythos to later expand it.",
        "Notice how space is not problematic, since a perception can be “just color” or something equally meaningless, such that we push the notions of space and extension into the realm of concepts.",
        "To be mindful you should be able to perceive without inducting. I’m not sure about enlightenment but I think it lies in that area broadly. Of course, you must give up the system to truly understand its truth, since the system is itself a concept and we wish to simply perceive. This is in line with what I’ve read about Zen and Buddhism, though, of course, it itself is not Buddhist wisdom. It’s just a concept.",
    ]
    
    return (
        <article>
            <h2>Exploring Quality</h2>
            <hr className={styles.top_seperator} />
            <section id="body-section">
                <h3>Zen and The Art</h3>
                <section id="book-review">
                    <p>There exist a handful of books (and other literary works) that stand out due to their unique ability to act as idea generators. Not only are these works able to present deep ideas in a clear, entertaining, memorable, and accessible manner, but they also manage to compress a high quantity of fundamental concepts together into a small space.</p>
                    <p>In a way, these concepts are less like knowledge and more like know-how. Sometimes I call them “know what” to highlight the analogy. The similarity lies in that they teach us not what to think, but <i>how</i> to think—for me they’ve given me new spaces of problems to explore. I think these works are valuable, and will become <i>more</i> valuable the more they are read.</p>
                    <p>For me these have included books like <i>Gödel Escher Bach</i>, <i>The Black Swan</i>, to a lesser extent <i>Antifragile</i>, and potentially <i>The Way of Zen</i>, some of Plato’s Socratic Dialogues, and Hume’s Treatise of Human Nature {footlink(1)}. I’d like to add Robert M. Pirsig’s <i>Zen and The Art of Motorcycle Maintenance</i> to the mix.</p>
                    <p>In it, Robert recounts the semi-autobiographical story of a motorcycle road trip with Chris—his eight year old son. Half of the time he recounts his travels and half of the time he tells the story of Phaedrus through flashbacks. A few years before the road trip, the protagonist (who I’ll call Robert) suffered a mental breakdown (it seems to have been some form of depression or insanity) and had his memories from before (somewhat) wiped. His past self, he dubs “Phaedrus,” whose history and ideas he remembers only in broad strokes. Before his breakdown, Phaedrus was on a metaphysical quest to understand value. Phaedrus’ goal seems to have been to create a rigorous philosophical framework based on, not truth, but Quality—that ineffable sense of what is good and what is right. Through these flashbacks Robert comments on modern life, while highlighting a few relevant distinctions regarding how we think.</p>
                    <p>Phaedrus’ main pursuit was a framework within which Quality was king. There is this notion of “mythos.” The mythos of a culture comprises its underlying understanding of reality through myths, religion, and the conceptual tree that underpins their belief systems. According to Robert, our Mythos has been passed down since the founding of Plato’s Academy, and its nature is to put rationality above all else {footlink(2)}. Truth is the ultimate quantity; dialectic {footlink(3)} (and to a lesser extent, experiment) are the methods used to arrive at it. Most intellectual (and probably also quotidian) concepts in our society inhabit this mythos. Our mythos is passed from generation to generation through (roughly) the university system, which Phaedrus dubs “The Church of Reason,” and in which our progeny are taught to think the <i>right</i> way (and thus perpetuate this church {footlink(4)}). Phaedrus’ goal is to overturn (at least within his own mind) the mythos {footlink(5)}. He sought an older form: one in which Quality generated everything, not Truth. Quality is Aretê {footlink(6)}, Dharma, Value, Goodness. It is that which we <i>know</i> is Good, but for which there is no reason <i>why</i> it is good. Quality is our first understanding of the world. Before we are trained to eschew Quality in favor of Truth, we analyze the world in terms of Quality.</p>
                    <p>Robert notes problems of our technological society. His angle is from motorcycle maintenance, but he also discusses the adverse reaction he sees (to technology) in some friends of his. For the former, the discussion is primarily about fixing motorcycles well and writing high Quality manuals. Most manuals are unreadable due to the lack of passion {footlink(7)} put into it by the writers—they just don’t care, so they do the minimum possible to meet whatever metrics they need to meet. He also notes a similar lack in the whole stack of motorcycle maintenance: the mechanics do a sloppy job, the procurers of tools are slow, etcetera. Regarding his friends, he notes how they don’t want anything to do with technology. Whenever anything breaks they call an expert, and they are anguished at the thought of having to deal with technology.</p>
                    <p>Robert introduces the split between Classical and Romantic thought as a concept to highlight the source of these issues. The Classical mind is concerned with components and categories and logic. It is the progenitor of technology. The Romantic mind is interested in instantaneous Quality—the arts, an elegant mathematical proof (to a mathematician), etcetera. We are often trained in exclusively one of these two, and so fail to grasp the other. Robert’s friends were primarily Romantic thinkers, so they could simply not appreciate the Quality lying within technology. Technology’s Quality is not in its forms, but in the concepts underlying its forms. In fact, Robert sees good engineering as a form of art—just that the Quality is in a different space (so to speak).</p>
                    <p>One problem with our society is that we are naturally Romantic thinkers, and thus alienated from a world that does not easily share its Quality. Those who make the technology are usually educated into the mythos of rationality, so they eschew various facets of Quality in favor of those which are more conceptual. Thus, most technology’s Quality is lacking in fullness. It is not full of Quality—only certain, hidden, aspects have it. There is the additional problem that those who are overeducated in our mythos may eschew Quality entirely. The employees who simply execute the tasks handed to them in a rational way fail to provide Quality work, which is why those mechanics and manual writers were so bad (and unmotivated—doing Qualityless work for long periods of time is depressing {footlink(8)}). He says that to create high Quality work, we must cultivate a certain peace of mind and sense of gumption. I won’t dive into those, but his discussion is valuable.</p>
                    <p>This lack of Quality in our mythos is also why, in many ways, school sucks. School simply does not pursue Quality, but instead seeks to endow you with rationality. For Phaedrus this was most tangible in his rhetoric class, where he was forced to teach sterile forms in a logical way. This method kills the true Quality of rhetoric from which the forms originated, and thus ruins the class. It must be Quality which precedes logical forms and argument, not the other way around. The mistaken reversal of these two things, as handed down to us through our mythos, is most blatant to Phaedrus in Aristotle's statement that “rhetoric is an art, because it can be reduced to a rational system of order.” For Phaedrus (and to some extent for me {footlink(9)}) statements like these are painful to read for this reason {footlink(10)}.</p>
                    <p>Phaedrus’ elevation of quality to be above dialectic/reason has interesting implications. It forces us to examine our methods for confirming truth, for example. It also opens up an exploration of the limitations of reason {footlink(11)}. For Phaedrus, this was important, because he wanted to expand our mythos (I know I’ve said “overturn” before, but “expand” is more accurate {footlink(12)}). It also explains various philosophical stances that are not necessarily logically defended. I have friends who debate whether democracy is right. Many people believe in democracy as a Quality decision, not a logical one (I think I do too). Debates like these seem dissociated from our sense of the real world. Lastly, Robert provides an interesting exploration of Quality giving rise to the subjective/objective split (i.e. Quality judgements are not subjective or objective).</p>
                </section>
                <h3>My System</h3>
                <section id="my-system">
                    <p>In conjunction with my Hume (and Spinoza) class, <i>Zen and The Art of Motorcycle Maintenance</i> has motivated me to form a formal (more informal than formal) system to describe reality from a first person perspective. Hume describes all objects as being of “perceptions” (including ideas, sensorial impressions, and so on). However, his perceptions do not seem to match directly to my firsthand experience of perceiving. They correspond to something more like concepts. My goal is to form a system akin to Hume’s such that the perceptions come <i>first</i> just how Quality comes first. Here I’ll just provide a high-level draft, but I intend to flesh it out (symbolically) later.</p>
                    <p>In my system perceptions give rise to all concepts, including the self, including an exterior world, and including Truth. There is a mechanism which gives rise to all these concepts, which I call “induction.” Induction (at least in humans) is able to form Quality judgements and form concepts, which are effectively analogies to the real world. This analogy I refer to as an “isomorphism.” It is called as such because it creates mental perceptions that are analogous, or isomorphic, to their real-world counterparts.</p>
                    <p>When we use language we are using induction on our concepts. It works like this: we induct on our concepts to form analogous linguistic representations, then we write them down; then someone inducts on the paper to turn it into a linguistic representation in their mind, after which they induct again to turn it into a concept. In theory, logic works similarly to language. Both our logical and linguistic faculties are either trained or predisposed to induct in a certain way, but there may be variation.</p>
                    <p>I’m not sure what I want to do about time {footlink(13)}. Fundamentally, there is no difference between considering all of life a single perception (such that induction applies in a certain manner to create time), and there being temporal succession of perceptions. On one hand, temporal succession of perceptions compels me to discuss the issue of continuous vs. discrete time, which I find problematic, since I’d be dealing with the concepts and not their progenitors. However, it’s hard to see how I might flesh out perception without temporal succession. I’ll be thinking about this for the next couple days.</p>
                    <p>On a related note, I’m also not entirely sure what I want to do about events. In the case where I choose to use a model of temporal succession, events will simply be temporally succeeding perceptions, such that induction on those events creates the concept of “event.” In the case where I have a single perception I’m not sure. Overall, in theory I should not have to deal with time at all, since it is a concept and not a perception. However, it seems so fundamental in our psyche that if I cannot clearly point to where it lies in the system, my system will not be useful.</p>
                    <p>My goals for my system are two-fold. On one hand, I want it to be a substratum of reasoning. I want to be able to reason about the system in the system’s own terms. For example, note how the system is a concept inducted from perceptions of concepts in general (more precisely, on the formation of concepts in my mind). In the system’s own lingo, the system is not the same as the progenitor perceptions. This system is not the real world. It is analogous to it. The reason I want it to be a substratum of reasoning is because it’s more accurate to the way things are than most objective/subjective-logic-above-all style frameworks. The perceptions plus induction allow us to point to the source of Quality. They also allow us to elucidate the path towards understanding in the manner of Zen {footlink(14)}.</p>
                    <p>On the other hand, I think that my system can be expanded and used to flesh out inductive biases for machine learning systems. The correct ordering of mental events is important for the creation of genuine intelligence, and I think that as we create AI systems for more complicated tasks, having such a framework will help me think about thinking.</p>
                    <p>I’m not sure how many/often cognitive scientists or machine learning researchers meditate (i.e. to gain insight), but it seems to me to be important to understand the mind. My system can only be “demonstrated” by applying induction on the perceptions acquired by observing the mind nonjudgmentally and with a deep focus. Deduction is to be used primarily for implementation, but on a fundamental level, you can’t reason about the system—you have to be an empiricist (which I personally prefer: it’s hard to be tricked by language).</p>
                </section>
            </section>
            <section id="footnotes-section">
                {footnotes.map((note, index) => footnote(index + 1, note))}
            </section>
            <section>
                <div className={styles.phone_home}>
                <a href="/">Click me to return home.</a>
                </div>
            </section>
        </article>
    )
}